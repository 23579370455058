//
// Google font - Poppins
//

@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");

@font-face {
    font-family: 'STC_Forward';
    src: url('../fonts/STCForward-Medium.eot');
    src: url('../fonts/STCForward-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/STCForward-Medium.woff') format('woff'),
        url('../fonts/STCForward-Medium.ttf') format('truetype');
    font-weight: 500;
  
  }