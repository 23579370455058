/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

// regular style toast
@import "~ngx-toastr/toastr";

// @font-face {
//   font-family: "STCFont";
//   src: url("./assets/fonts/stc/STCForwardRegular.ttf");
//   src: url("./assets/fonts/stc/STCForwardBold.ttf");
// }
@font-face {
  font-family: "STCFont";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  font-size: 12px;
  src: url("./assets/fonts/stc/STC\ Forward\ Regular.ttf");
  src: url("./assets/fonts/stc/STC\ Forward\ Bold.ttf");
}

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

body {
  font-family: "STCFont", "sans-serif";
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.modal-backdrop {
  z-index: 1050 !important;
}





/* 
phone validation
 */

.iti {
  display: block !important;
}

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;
  background-color: #131725;
  border-radius: 20px;
  // max-width: 375px;
  overflow: overlay;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  left: 0;
  right: unset;
  width: 400px;
  max-height: 170px;
}



.iti__flag-container.open+input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.iti .search-container input {
  font-size: 14px;
  border-color: #c7cace;
  border-radius: 0;
  padding: 5px 10px;
}

.iti .search-container input:focus {
  outline: none;
}

@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}

.iti__selected-flag,
.iti__selected-flag:hover {
  direction: ltr;
  background: #bebebe;
  border-radius: 4px;
  // color: #fff;
}

.iti__country {
  direction: rtl;
  /* float: right; */
  width: 100%;
  text-align: right;
  overflow: hidden;
}

.iti__country:hover {
  background: #3388de;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  max-height: 170px;
  background: #131725;
  color: #fbfbfb;
}

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
  border-radius: 100px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #6975a0;
  border-radius: 100px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(#2268fb, #30dde5);
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(#2268fb, #30dde5);
}
